
//this is carousel compnents
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import 'swiper/css/pagination';
import Cardproduct from "./Cardproduct";
import HomeImagecard from "./HomeImagecard"
import HeadingTitle from "./HeadingTitle";
import { useSelector } from "react-redux";
import BlogPosts from "./BlogPosts";
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
const SingleCarousel = ({ position, add, single, carouseldata, Blog, description, filterdata, categorydata, dattt, heading, viewall, button_url }) => {
    const carouselseletd = useSelector((state) => state.Home1Reducer.carouselselected);
    const navigationstatus = useSelector(state => state.masterdata.NavigationStatus);
    return (
        <>
            <div className={`space-20`}></div>
            {
                heading !== 'noheading-for-multiplecarousel' &&
                <>
                    {single && navigationstatus &&
                        navigationstatus.find(ele => ele.component === "section-headding-setting") ?
                        navigationstatus.find(ele => ele.component === "section-headding-setting").sectionResult[0]?.headding_underline === "1" && (
                            <div className="home-carousel-component-header">
                                <div className="Quality-products">
                                    <h5 className="Quality-products-details-left1"></h5>
                                    <h5 className="Quality-products-details-left2">{heading}</h5>
                                </div>
                                <NavLink className="text-decoration-none" to={button_url}>
                                    <h5 className="home-carousel-componentheader-viewall">{viewall}</h5>
                                </NavLink>
                            </div>
                        ) :
                        <>
                            <HeadingTitle
                                className={"center"}
                                HeadingTitle={heading}
                                content={description}
                                titleclass={"product-detail-bottom"}
                                button_url={button_url}
                                viewall={viewall}
                            />
                            <h6 class="viewallbutton">{viewall}</h6>
                        </>
                    }
                </>
            }

            {/* {single && navigationstatus &&
                navigationstatus.map((ele) => {
                    if (ele.component === "section-headding-setting") {
                        if (ele.sectionResult[0]?.headding_underline === "1") {
                          
                                <>
                                    <div className="home-carousel-component-header">
                                        <div className="Quality-products">
                                            <h5 className="Quality-products-details-left1"></h5>
                                            <h5 className="Quality-products-details-left2">{heading}</h5>
                                        </div>
                                        <NavLink className="text-decoration-none" to={button_url}>
                                            <h5 className="home-carousel-componentheader-viewall">{viewall} </h5>
                                        </NavLink>
                                    </div>
                                </>
                            
                        }
                    }
                    else {
                        return (
                            <>
                                <HeadingTitle
                                    className={"center"}
                                    HeadingTitle={heading}
                                    content={description}
                                    titleclass={"product-detail-bottom"}
                                    button_url={button_url}
                                    viewall={viewall}
                                />
                                <h6 class="viewallbutton">{viewall}</h6>
                            </>
                        );
                    }
                    return null; // Return null if the condition is not met
                })
            } */}


            <div className="swiper-main swiper-padd">

                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={15}
                    effect="cards"
                    // loop={true}
                    className=""
                    navigation={{
                        nextEl: `.nextbtn${position}`,
                        prevEl: `.previousbtn${position}`,
                    }}
                    breakpoints={{
                        200: {
                            slidesPerView: 2,
                        },
                        476: {
                            slidesPerView: 2,
                        },
                        700: {
                            slidesPerView: 2,
                        },
                        750: {
                            slidesPerView: 3,
                        },
                        1000: {
                            slidesPerView: localStorage.getItem("carousel_product_view"),
                        },
                        1200: {
                            slidesPerView: localStorage.getItem("carousel_product_view"),
                        },
                        1400: {
                            slidesPerView: localStorage.getItem("carousel_product_view"),
                        },
                        1600: {
                            slidesPerView: localStorage.getItem("carousel_product_view"),
                        },
                        1800: {
                            slidesPerView: localStorage.getItem("carousel_product_view"),
                        },
                    }}
                // className={`mycategorySwiper${1}`}

                >
                    <div>


                        {carouseldata && carouseldata.length > 0 &&
                            carouseldata.map(ele => {
                                return (
                                    <SwiperSlide >

                                        <Cardproduct image={ele.product_images[0]} title={ele.product_title} rating={ele.rating} slug={ele.slug} status={ele.type} price={ele.selling_price} discount={ele.discount_percentage} productid={ele.productid} mrp={ele.mrp} stock_status={ele.stock_status} product_type={ele.product_type} />
                                    </SwiperSlide>
                                )
                            })
                        }
                        {/* {Relateddata && Relateddata.length > 0 &&
                            Relateddata.map(ele => {
                                return (
                                    <SwiperSlide >

                                        <Cardproduct image={ele.product_images[0]} title={ele.product_title} rating={ele.rating} slug={ele.slug} status={ele.type} price={ele.selling_price} discount={ele.discount_percentage} productid={ele.productid} mrp={ele.mrp} />
                                    </SwiperSlide>
                                )
                            })
                        }
                        {Resentlydata && Resentlydata.length > 0 &&
                            Resentlydata.map(ele => {
                                return (
                                    <SwiperSlide >

                                        <Cardproduct image={ele.product_images[0]} title={ele.product_title} rating={ele.rating} slug={ele.slug} status={ele.type} price={ele.selling_price} discount={ele.discount_percentage} productid={ele.productid} mrp={ele.mrp} />
                                    </SwiperSlide>
                                )
                            })
                        } */}
                        {filterdata &&
                            filterdata[0] && filterdata[0].items && filterdata[0].items.length > 0 &&
                            filterdata[0].items.map(ele => {
                                return (
                                    <SwiperSlide >

                                        <Cardproduct image={ele.product_images[0]} title={ele.product_title} rating={ele.rating} slug={ele.slug} status={ele.status} price={ele.selling_price} discount={ele.discount_percentage} mrp={ele.mrp} stock_status={ele.stock_status} product_type={ele.product_type} />
                                    </SwiperSlide>
                                )
                            })
                        }
                        {
                            categorydata && categorydata.length > 0 &&
                            categorydata.map(ele => {
                                return (

                                    <SwiperSlide >
                                        <HomeImagecard image={ele.image} title={ele.label} slug={ele.slug} />
                                    </SwiperSlide>
                                )
                            })
                        }

                    </div>

                </Swiper>
                <div className='space-30'></div>
                <div >
                    <div className={`previousbtn primaryColorHover secondaryColor previousbtn${position} `}><BiSolidChevronLeft /></div>
                    <div className={`nextbtn primaryColorHover secondaryColor nextbtn${position} `}><BiSolidChevronRight /></div>
                </div>
            </div>
            <div className={` space-30`}>   </div>
        </>
    )
}
export default SingleCarousel;